// ! coming soon routing
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Preloader from "./comingSoon/Preloader/Preloader";
import Timer from "./comingSoon/Countdown/Timer";
import Optin from "./comingSoon/Optin/Optin";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/free-solid-svg-icons';
import "./comingSoon/comingSoonStyle.css";

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  
  return (
    <>
    <div className="App">
      <div className="container">
        <h1 style={{margin:'5px'}} className='title'>
          <FontAwesomeIcon icon={faPaw} style={{color: 'rgb(227, 100, 54)'}}/> Paws For Hire
        </h1>
          <br/>
        <h2 className="comingSoon">
          Coming Soon!
        </h2>
        <Timer />
        <Optin />
        <Preloader />
      </div>
    </div>
    </>
  );
}

export default App;
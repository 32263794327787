import React, { Component } from 'react';
import './Optin.css';
import '../comingSoonStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTiktok, faFacebook } from '@fortawesome/free-brands-svg-icons'; 

class Optin extends Component {
  render() {

    return (
      <div className="optin">
        <p><FontAwesomeIcon icon={faPaw} style={{color: 'rgb(227, 100, 54)'}}/> Follow us on social media for future paw updates!</p>
        <a href='https://www.instagram.com/paws_4_hire/' rel="noopener noreferrer" target="_blank"><FontAwesomeIcon className='cardIcon' icon={faInstagram} id='ig'/> </a> 

        <a href='https://www.tiktok.com/@paws.for.hire' rel="noopener noreferrer" target="_blank"><FontAwesomeIcon className='cardIcon' icon={faTiktok } id='tiktok'/> </a>

        <a href='https://www.tiktok.com/@paws.for.hire' rel="noopener noreferrer" target="_blank"><FontAwesomeIcon className='cardIcon' icon={faFacebook } id='tiktok'/> </a>
      </div>
    );
  }
}

export default Optin;
